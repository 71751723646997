<template>
  <div class="view">
    <div class="top">
      <div class="title">
        患者您好，进行咨询前请选择就诊人：<span style="color: red">*</span>
      </div>
      <div class="jzr" v-if="formObj.id">
        <span class="jzr-left">
          <img class="touxiang" src="../assets/touxiang.png" />
          <span class="name">{{ formObj.name }}</span>
          <span class="sex-age"
            >{{ formObj.sex == 2 ? "女" : "男" }} |
            {{ formObj.age || "--" }}岁</span
          >
        </span>
        <div class="jzr-rigth">
          <img @click="showThePatient" src="../assets/img/icon/qiehuan.png" />
        </div>
      </div>
      <div class="addjzr" v-else @click="patientAdd">
        <img class="addIcon" src="../assets/img/icon/add-hui.png" />
        <span>添加就诊人</span>
      </div>
    </div>
    <div>
      <div>
        <div class="hTitle">
          <img src="../assets/ti.png" />
          病情概述
          <span style="color: red">*</span>
        </div>
        <div>
          <van-field
            v-model="formObj.conditionContent"
            rows="7"
            autosize
            clearable
            type="textarea"
            maxlength="500"
            placeholder="请在此处填写您的线下就诊情况、医生治疗方案等内容（病情概述不能少于10个字）"
            show-word-limit
          />
        </div>
        <div class="hTitle">
          <img src="../assets/ti.png" />
          病历资料
        </div>
        <div class="caseData">
          <!-- caseImgList -->
          <div class="item" v-for="item in formObj.caseImgList" :key="item.id">
            <van-icon
              name="close"
              class="item-close"
              @click="handleDelete(item)"
            />
            <img
              @click="lookImg(item.url,formObj.caseImgList)"
              v-if="item.type.indexOf('image/') == 0"
              :src="item.url"
            />
            <img
              @click="toHref(item.url, item.name)"
              v-else
              src="../assets/img/icon/wenjian.png"
            />
            <span class="item-file-name">{{ item.name }}</span>
          </div>
          <div class="item add">
            <label>
              <input
                type="file"
                @change="changeFile($event)"
                style="display: none"
				ref="fileAddbtn"
                
              />
			  <!-- multiple -->
              <img src="../assets/img/icon/gengduo.png" />
            </label>
          </div>

          <div class="item">
            <label>
              <input
                type="file"
                accept="image/*"
                capture="camera"
                @change="addImg"
                style="display: none"
              />
              <img
                style="width: 100%; height: 100%"
                src="../assets/img/icon/baishe.png"
              />
            </label>
          </div>
        </div>
        <div style="width: 6.9rem; margin: 10px auto">
          <van-button
            class="btn-submit"
            @click="toRoom"
            type="primary"
            size="large"
            round
			loading-type="spinner"
			:loading="isLoading.venice"
            >立即咨询</van-button
          >
        </div>
      </div>
    </div>
    <!-- 就诊人选择 -->
    <van-popup
      v-model:show="isShow.patList"
      round
      position="bottom"
      class="patient-box"
    >
      <div v-if="patList.length < 5" class="add-patient" @click="patientAdd">
        新增就诊人 >
      </div>
      <van-picker
        ref="patChangePicker"
        :columns="patList"
        :columns-field-names="{ text: 'name', value: 'id' }"
        @cancel="isShow.patList = false"
        @confirm="selectThePatient"
      />
    </van-popup>
  </div>
</template>

<script>
import {
  Icon,
  Dialog,
  Button,
  Notify,
  Toast,
  Field,
  ImagePreview,
  Popup,
  Picker,
} from "vant";
// 图片压缩
import lossyImg from "../mixins/lossyImg.js";
export default {
  name: "orderDetail",
  components: {
    [Icon.name]: Icon,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Notify.name]: Notify,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
  },
  mixins: [lossyImg],
  data() {
    return {
      formObj: {
        caseImgList: [],
      },
      isShow: {
        patList: false,
      },
      patList: [],
	  isLoading:{
		  venice:false,
	  },
    };
  },
  created() {
    this.queryPatList();
  },
  computed: {},
  mounted() {},
  methods: {
    //获取就诊人
    queryPatList() {
      this.$http
        .request("queryPatList", {
          patientId: this.$cache.local.getItem("patientId"),
        })
        .then((res) => {
			let seeDoctorId=""
			if(this.$route.query.seeDoctorId){
				seeDoctorId=this.$route.query.seeDoctorId
			}else if(this.$cache.local.getItem("consultPagePatientId")){
				seeDoctorId=this.$cache.local.getItem("consultPagePatientId")
			}
			let formObjSeeDoctor=res[res.length - 1];
			if(seeDoctorId){
				for(let i in res){
					if(res[i].id==seeDoctorId){
						formObjSeeDoctor=res[i];
						break;
					}
				}
			}
			
          console.log(res, "就诊人列表");
          if (res && res.length > 0) {
            this.patList = res;
            // 如果列表没有返回所有数据》这里需要添加获取详情请求---》同选择就诊人
            let formObj = {
              ...formObjSeeDoctor,
              caseImgList: [],
            };
            formObj.caseImgList = this.formObj.caseImgList;
            formObj.conditionContent = this.formObj.conditionContent;
            this.formObj = formObj;
          } else {
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 显示就诊人
    showThePatient() {
      this.isShow.patList = true;
      setTimeout(() => {
		  let sIndex=(this.patList.length - 1)>0?(this.patList.length - 1):0;
		  for(let i=0;i<this.patList.length;i++){
			  if(this.patList[i].id==this.formObj.id){
				  sIndex=i;
				  break
			  }
		  }
        this.$refs.patChangePicker.setIndexes([sIndex]);
      }, 0);
    },
    // 选择就诊人
    selectThePatient(item) {
      if (item) {
        let formObj = { ...item };
        formObj.caseImgList = this.formObj.caseImgList;
        formObj.conditionContent = this.formObj.conditionContent;
        this.formObj = formObj;
      }
      this.isShow.patList = false;
    },
    // 立即咨询并跳转咨询页
    toRoom() {
      if (this.formObj.id) {
        let item = this.formObj;
        if (item) {
          let f = {
            patientId: this.$cache.local.getItem("patientId"),
            id: item.id,
            caseImg: JSON.stringify(item.caseImgList),
            conditionContent: item.conditionContent,
          };
          // 机构id companyId
          if (this.$cache.local.getItem("appid") == this.$store.state.appid) {
            f.addrId = this.$cache.session.getItem("addrId");
            if (!f.addrId) {
              alert("请在首页选择区域后进行咨询");
              return;
            }
          } else {
            f.organizationId = this.$cache.local.getItem("companyId");
          }
          if (this.$route.query.docId) {
            f.doctorId = this.$route.query.docId;
          }
          if (!this.formObj.conditionContent) {
            Toast.fail("请填写病情概述");
            return;
          } else {
            if (this.formObj.conditionContent.length < 10) {
              Toast.fail("病情概述不能少于10个字");
              return;
            }
          }
		  this.isLoading.venice=true;
          this.$http
            .request("venice", f)
            .then((res) => {
				// 存储此次咨询就诊人id
				this.$cache.local.setItem("consultPagePatientId",res.seeDoctorId);
				
				// 发送文字消息
				  let f = {
				    roomId: res.id,
				    seeDoctorId: res.seeDoctorId,
				    // assistantConsultId: this.$route.query.id,
				    // assistantSessionId: this.$route.query.assistantSessionId,
				    content: JSON.stringify({
						id:this.formObj.id,
						age:this.formObj.age,
						sex:this.formObj.sex,
						name:this.formObj.name,
						conditionContent:this.formObj.conditionContent,
					}),
				    msgType: 13,
				    patientId: this.$cache.local.getItem("patientId"),
				    sendType: 2,
				  };
				  
				  this.$http
				    .request("sendRoomMsg", f)
				    .then((restow) => {
						this.isShow.patList = false;
						if(this.$route.query.docId){
							this.$http.request("zxCountClick",{id:this.$route.query.docId}).then(()=>{}).catch(()=>{})
						}
						// 跳转到医住聊天会话页面
						this.$router.push({
						  path: "/yizhuChatRoom",
						  query: {
						    // accountId: res.accountId,
						    // assistantSessionId: res.assistantSessionId,
						    seeDoctorId: res.seeDoctorId,
						    id: res.id,
						  },
						});
					})
				    .catch(() => {
						this.isLoading.venice=false;
					});
            })
            .catch(() => {
				this.isLoading.venice=false;
			});
        }
      } else {
        Toast.fail("请添加就诊人");
      }
      // console.log(this.formObj, "this.formObj666");
    },
    // 选择文件
    changeFile(e) {
	
      if (e.target.files.length != 0) {
        let files = e.target.files;
        Array.from(files).map((item) => {
          console.log(item);
          if (item.type.indexOf("image") == 0) {
            this.uploadPics(item, e);
          } else {
            this.uploadFile(item, e);
          }
        });
      }else{
		  console.log("没有选择图片")
	  }
    },
    // 上传文件
    uploadFile(item, e) {
      if (item) {
        let file = item;
        if (file.type.indexOf("image") == 0) {
          this.addImg(e);
          return;
        }
        let f = new FormData();
        f.append("file", file);
        f.append("fileType", 0);
        f.append("imgAbridge", false);
        let t = Toast.loading({
          duration: 0,
          message: "请稍等...",
        });
        this.$http
          .request("uploadImg", f)
          .then((res) => {
            t.clear();
            this.formObj.caseImgList.push({
              url: res.bigImg,
              type: file.type || "",
              name: file.name,
            });
            e.target.value = "";
          })
          .catch((e) => {
            t.clear();
            e.target.value = "";
            // file.onError(e)
          });
        console.log(this.formObj.caseImgList, "this.formObj.caseImgList");
      }
    },
    // 上传图片
    uploadPics(item, e) {
      console.log("上传图片");
      if (item) {
        this.compressorImage(item)
          .then((fl) => {
            let tload = Toast.loading({
              duration: 0,
              message: "请稍等...",
            });
            let newF = new FormData();
            // newF.append("Val ", "1");
            newF.append("imgAbridge  ", false);
            newF.append("file", fl);
            newF.append("fileType", 1);
            this.$http
              .request("uploadImg", newF)
              .then((res) => {
                tload.clear();
                this.formObj.caseImgList.push({
                  url: res.bigImg,
                  type: fl.type || "",
                });
                // 获取到文件地址
                // ws 发送文件地址
                e.target.value = "";
              })
              .catch((err) => {
                console.log(err);
                tload.clear();
                e.target.value = "";
              });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    // 预览图片
    lookImg(i,list) {
      let arr = []
      list.forEach((item) => {
        if (item.type && item.type.indexOf("image/") == 0) {
          arr.push(item.url);
        }
      });
      let num = 0;
      arr.forEach((ele, eidx) => {
        if (i == ele) {
          num = eidx;
        }
      });
      ImagePreview({
        images: arr,
        startPosition: num,
      });
    },
    // 跳转连接
    // toHref(i) {
    //   window.location.href = i;
    // },
    // 下载文件-修改文件名
    toHref(url, filename) {
      this.getBlob(url, (blob) => {
        this.saveAs(blob, filename);
      });
    },
    getBlob(url, cb) {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = function () {
        if (xhr.status === 200) {
          cb(xhr.response);
        }
      };
      xhr.send();
    },
    saveAs(blob, filename) {
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        var link = document.createElement("a");
        var body = document.querySelector("body");

        link.href = window.URL.createObjectURL(blob);
        link.download = filename;

        link.style.display = "none";
        body.appendChild(link);

        link.click();
        body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      }
    },
    // 删除
    handleDelete(item) {
      console.log(this.formObj.caseImgList, item, "666666666");
      this.formObj.caseImgList.forEach((ele, index) => {
        if (ele.url == item.url) {
          this.formObj.caseImgList.splice(index, 1);
        }
      });
    },
    // 添加就诊人
    patientAdd() {
      let v = new Date().getTime();
      // 缓存填写信息
      // 病情概述、病历资料
      let f = {
        caseImgList: this.formObj.caseImgList,
        conditionContent: this.formObj.conditionContent || "",
        v: v,
      };
      console.log(f);
      this.$cache.session.setItem("toConsultInfo", f);
      this.$router.push({
        path: "/patientAdd",
        query: {
          v: v,
        },
      });
    },
    addImg(e) {
      console.log("上传图片");
      if (e.target.files[0]) {
        this.compressorImage(e.target.files[0])
          .then((fl) => {
            let tload = Toast.loading({
              duration: 0,
              message: "请稍等...",
            });
            let newF = new FormData();
            // newF.append("Val ", "1");
            newF.append("imgAbridge  ", false);
            newF.append("file", fl);
            newF.append("fileType", 1);
            this.$http
              .request("uploadImg", newF)
              .then((res) => {
                tload.clear();
                this.formObj.caseImgList.push({
                  url: res.bigImg,
                  type: fl.type || "",
                });
                // 获取到文件地址
                // ws 发送文件地址
                e.target.value = "";
              })
              .catch((err) => {
                console.log(err);
                tload.clear();
                e.target.value = "";
              });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    let info = window.sessionStorage.getItem("hzb_brd_toConsultInfo");
    if (info) {
      try {
        info = JSON.parse(info);
        if (info.v == from.query.v && info.v && from.path == "/patientAdd") {
          next((vm) => {
            vm.formObj.caseImgList = info.caseImgList;
            vm.formObj.conditionContent = info.conditionContent;
          });
        } else {
          console.log(info, from);
          next();
        }
      } catch {
        next();
      }
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
.view {
  .top {
    position: relative;
    background-color: #278eff;
    height: 120px;
    width: 100%;
    margin-bottom: 50px;
    .title {
      padding: 30px 15px 0;
      font-size: 14px;
      color: #fff;
      line-height: 20px;
    }
    .addjzr {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 70px;
      left: 0.3rem;
      width: 6.9rem;
      height: 80px;
      background-color: #fff;
      box-shadow: 0px 2px 6px 0px rgba(225, 225, 225, 0.5);
      border-radius: 6px;
      padding: 0 10px;
      box-sizing: border-box;
      color: #9da7b1;
      font-size: 14px;
      .addIcon {
        width: 25px;
        height: 25px;
      }
    }
    .jzr {
      position: absolute;
      display: flex;
      justify-content: space-between;
      top: 70px;
      left: 0.3rem;
      width: 6.9rem;
      height: 80px;
      background-color: #fff;
      box-shadow: 0px 2px 6px 0px rgba(225, 225, 225, 0.5);
      border-radius: 6px;
      padding: 0 10px;
      box-sizing: border-box;
    }
    .jzr-left {
      display: flex;
      align-items: center;
      height: 100%;
      .name {
        font-weight: 500;
        color: #272d3b;
        font-size: 18px;
        margin-right: 10px;
      }
      .sex-age {
        font-size: 14px;
        font-weight: 400;
        color: #272d3b;
      }
    }
    .jzr-rigth {
      display: flex;
      align-items: center;
      height: 100%;
      > img {
        width: 25px;
        height: 25px;
      }
    }
    .touxiang {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }
  }
}
.hTitle {
  height: 22px;
  > img {
    height: 22px;
    margin-right: 8px;
  }
  font-size: 16px;
  margin: 10px 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.caseData {
  overflow: hidden;
  min-height: 150px;
  // 6rem +0.4rem
  margin-left: calc(0.45rem - 4px);
  width: calc(6.4rem + 8px);
  .item {
    float: left;
    width: 1.5rem;
    margin: 10px 0 0 0.1rem;
    height: 1.5rem;
    border: 1px solid #eeeeee;
    position: relative;
    .item-close {
      position: absolute;
      top: 1px;
      right: 1px;
    }
    .item-file-name {
      position: absolute;
      bottom: 1px;
      right: 1px;
      font-size: 10px;
      transform: scale(0.8);
      color: #5d6e7f;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &.add {
      img {
        width: 40px;
        height: 40px;
        margin-top: calc(0.75rem - 20px);
        margin-left: calc(0.75rem - 20px);
      }
    }
  }
}
.patient-box {
  position: relative;
  .add-patient {
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
    color: #278eff;
    font-size: 14px;
  }
}
</style>
